<template>
  <div class="pages">
    <h1>Cadastrar nova Câmara</h1>
    <button class="btn outline back-btn" v-on:click="$router.back()">Voltar</button>
    <div class="wrapper">
      <div>
        <label for="name">Nome</label>
        <input type="text" id="name" v-model="item.name" placeholder="Título">
      </div>
      <div>
        <label for="url">URL <small>ex: camara-do-crars</small></label>
        <input type="text" id="url" v-model="item.url" v-on:keyup="formatURL" placeholder="URL">
      </div>
      <div>
        <label for="img">Logo</label>
        <input type="file" id="img" v-on:change="imgChanged" accept="image/*" ref="img">
        <p v-if="savingImg">Fazendo upload...</p>
        <img v-if="item.logo" :src="$mediaBaseUrl + item.logo" :alt="item.name">
      </div>
      <div>
        <label>Comitê Gestor</label>
        <div class="editor-wrapper">
          <editor
                  ref="editor_content_manager"
                  autofocus
                  holder-id="codex-editor-content-manager"
                  save-button-id="save-button-manager"
                  :init-data="initData"
                  @save="saveManager"
                  @ready="onReadyManager"
                  @change="onChangeManager"
          />
        </div>
      </div>
      <div>
        <label>Titulares</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_holders"
              autofocus
              holder-id="codex-editor-content-holders"
              save-button-id="save-button-holders"
              :init-data="initData"
              @save="saveHolders"
              @ready="onReadyHolders"
              @change="onChangeHolders"
          />
        </div>
      </div>
      <div>
        <label>Suplentes</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_alternate"
              autofocus
              holder-id="codex-editor-content-alternate"
              save-button-id="save-button-alternate"
              :init-data="initData"
              @save="saveAlternate"
              @ready="onReadyAlternate"
              @change="onChangeAlternate"
          />
        </div>
      </div>
      <div>
        <label>Data de Criação</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_creation_date"
              autofocus
              holder-id="codex-editor-content-creation-date"
              save-button-id="save-button-creation-date"
              :init-data="initData"
              @save="saveCreationDate"
              @ready="onReadyCreationDate"
              @change="onChangeCreationDate"
          />
        </div>
      </div>
      <div>
        <label>Objetivos</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_goals"
              autofocus
              holder-id="codex-editor-content-goals"
              save-button-id="save-button-goals"
              :init-data="initData"
              @save="saveGoals"
              @ready="onReadyGoals"
              @change="onChangeGoals"
          />
        </div>
      </div>
      <div>
        <label>Atividades</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_activities"
              autofocus
              holder-id="codex-editor-content-activities"
              save-button-id="save-button-activities"
              :init-data="initData"
              @save="saveActivities"
              @ready="onReadyActivities"
              @change="onChangeActivities"
          />
        </div>
      </div>
      <div>
        <label>Principais Eventos</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_events"
              autofocus
              holder-id="codex-editor-content-events"
              save-button-id="save-button-events"
              :init-data="initData"
              @save="saveEvents"
              @ready="onReadyEvents"
              @change="onChangeEvents"
          />
        </div>
      </div>
      <div>
        <label>Informações para Contato</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_contact"
              autofocus
              holder-id="codex-editor-content-contact"
              save-button-id="save-button-contact"
              :init-data="initData"
              @save="saveContact"
              @ready="onReadyContact"
              @change="onChangeContact"
          />
        </div>
      </div>
      <br>
      <button class="btn save-btn" v-on:click="saveItem" :disabled="loading">{{ loading ? 'Salvando...' : 'Salvar' }}</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios').default;

export default {
  name: 'newitem',
  data() {
    return {
      loading: false,
      savingImg: false,
      item: {
        name: '',
        url: '',
        logo: '',
        manager: '',
        holders: '',
        alternate: '',
        creationDate: '',
        goals: '',
        activities: '',
        events: '',
        contact: ''
      },
      initData: {
        blocks: []
      },
    }
  },
  methods: {
    saveItem() {
      let error = false;

      if (!error) {
        this.loading = true;
        let self = this;

        let itemToSave = Object.assign({}, this.item);
        itemToSave.manager = JSON.stringify(itemToSave.manager);
        itemToSave.holders = JSON.stringify(itemToSave.holders);
        itemToSave.alternate = JSON.stringify(itemToSave.alternate);
        itemToSave.creationDate = JSON.stringify(itemToSave.creationDate);
        itemToSave.goals = JSON.stringify(itemToSave.goals);
        itemToSave.activities = JSON.stringify(itemToSave.activities);
        itemToSave.events = JSON.stringify(itemToSave.events);
        itemToSave.contact = JSON.stringify(itemToSave.contact);

        axios.post(this.$apiUrl + 'councils/create', itemToSave)
        .then(response => {
          self.loading = false;
          this.$alert(response.data.message, 'Sucesso', 'success').then(() => {
            self.$router.push('/camaras');
          });
        })
        .catch(error => {
          this.$alert(error.response.data.message, 'Erro', 'error');
          // eslint-disable-next-line no-console
          console.log(error.response.data.error);
          self.loading = false;
        });
      }
    },
    async imgChanged() {
      let self = this;
      let file = this.$refs.img.files[0];
      self.savingImg = true;

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        axios.post(this.$apiUrl + 'files/upload', formData)
                .then(response => {
                  self.item.logo = response.data.file.key;
                  self.savingImg = false;
                })
                .catch(error => {
                  // eslint-disable-next-line no-console
                  console.log(error);
                  self.savingImg = false;
                });
      }
    },
    formatURL() {
      this.item.url = this.item.url.replace(/\s+/g, '-').toLowerCase();
    },
    saveManager(response) {
      this.item.manager = response;
    },
    onReadyManager() {
      this.$refs['editor_content_manager'].save();
    },
    onChangeManager() {
      this.$refs['editor_content_manager'].save();
    },

    saveHolders(response) {
      this.item.holders = response;
    },
    onReadyHolders() {
      this.$refs['editor_content_holders'].save();
    },
    onChangeHolders() {
      this.$refs['editor_content_holders'].save();
    },

    saveAlternate(response) {
      this.item.alternate = response;
    },
    onReadyAlternate() {
      this.$refs['editor_content_alternate'].save();
    },
    onChangeAlternate() {
      this.$refs['editor_content_alternate'].save();
    },

    saveCreationDate(response) {
      this.item.creationDate = response;
    },
    onReadyCreationDate() {
      this.$refs['editor_content_creation_date'].save();
    },
    onChangeCreationDate() {
      this.$refs['editor_content_creation_date'].save();
    },

    saveGoals(response) {
      this.item.goals = response;
    },
    onReadyGoals() {
      this.$refs['editor_content_goals'].save();
    },
    onChangeGoals() {
      this.$refs['editor_content_goals'].save();
    },

    saveActivities(response) {
      this.item.activities = response;
    },
    onReadyActivities() {
      this.$refs['editor_content_activities'].save();
    },
    onChangeActivities() {
      this.$refs['editor_content_activities'].save();
    },

    saveEvents(response) {
      this.item.events = response;
    },
    onReadyEvents() {
      this.$refs['editor_content_events'].save();
    },
    onChangeEvents() {
      this.$refs['editor_content_events'].save();
    },

    saveContact(response) {
      this.item.contact = response;
    },
    onReadyContact() {
      this.$refs['editor_content_contact'].save();
    },
    onChangeContact() {
      this.$refs['editor_content_contact'].save();
    }

  },
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/global";
  @import "../../assets/scss/buttons";

  .pages {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px;
    position: relative;

    .back-btn {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 10px;
    }

    .wrapper {
      padding: 50px 200px;
      @media screen and (max-width: 800px) {
        padding: 50px;
      }
    }

    label {
      display: block;
      text-align: left;

      &:nth-of-type(1) {
        margin-top: 10px;
      }
    }

    input, select, textarea {
      padding: 10px;
      width: 100%;
      border: 1px solid $primary;
      border-radius: 10px;
      font-size: 1rem;
    }

    select {
      -webkit-appearance: none;
    }

    .save-btn {
      border-radius: 10px;
    }

    img {
      max-width: 50%;
      height: auto;
    }

    .editor-wrapper {
      text-align: left;
      background-color: #fff;
      border: 1px solid $primary;
      border-radius: 10px;
    }
  }
</style>
